<template>
  <div>
    <CRow>
      <CCol>
        <h2>
          Active Properties
          <span class="h4">({{ filteredProperties.length }})</span>
        </h2>
      </CCol>
      <CCol class="text-right">
        <CButton color="info" shape="pill" @click="showActivationModal = true">
          Add Property
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <ActivePropertyTable :data="filteredProperties" :loading="loading" />
      </CCol>
    </CRow>

    <PropertyActivationModal :show="showActivationModal" @close="showActivationModal = false" />
  </div>
</template>

<script>
import { RTableFilterOptions } from '@/components/tables'
import { RooofAPI } from '@/services/api/resources'
import { capitalize } from '@/utils'
import constants from '@/utils/constants'

import PropertyActivationModal from '../_components/PropertyActivationModal'
import ActivePropertyTable from './ActivePropertyTable'

export default {
  name: 'ActivePropertyList',
  /**
   * React to param changes to refresh the property data.
   */
  beforeRouteUpdate (to, from, next) {
    if (to.params.cid !== from.params.cid) {
      this.fetchPropertyList(to.params.cid)
    }
    next()
  },
  components: {
    ActivePropertyTable,
    RTableFilterOptions,
    PropertyActivationModal
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      properties: [],
      loading: false,
      filters: {
        status: [],
        state: [],
        propertyName: ''
      },
      showActivationModal: false
    }
  },
  computed: {
    filterOptions () {
      const states = new Set()
      const status = new Set()
      const statusCounts = {}

      for (const prop of this.properties) {
        if (prop.address.state !== null) {
          states.add(prop.address.state)
        }
        status.add(prop.status)
        statusCounts.hasOwnProperty(prop.status)
          ? statusCounts[prop.status] += 1
          : statusCounts[prop.status] = 1
      }
      return {
        state: Array.from(states).sort().map(state => ({
          label: state || '(none)',
          value: state
        })),
        status: Array.from(status).sort().map(status => ({
          label: `${capitalize(status)} (${statusCounts[status]})`,
          value: status
        }))
      }
    },
    filterConfig () {
      return {
        status: {
          type: 'multiselect',
          label: 'Status',
          options: this.filterOptions.status
        },
        state: {
          type: 'multiselect',
          label: 'State',
          options: this.filterOptions.state
        },
        propertyName: {
          type: 'input',
          label: 'Property Name'
        }
      }
    },
    filteredProperties () {
      return this.properties.filter(prop => {
        let include = true
        if (this.filters.state.length > 0) {
          include &= this.filters.state.includes(prop.address.state)
        }
        if (this.filters.status.length > 0) {
          include &= this.filters.status.includes(prop.status)
        }
        if (this.filters.propertyName !== '') {
          include &= prop.name.toLowerCase().includes(this.filters.propertyName.toLowerCase())
        }
        return include
      })
    }
  },
  created () {
    this.fetchPropertyList(this.$route.params.cid)
  },
  methods: {
    /**
     * Fetch the list of non-inactive properties for this company.
     *
     * @param {Number} id
     */
    async fetchPropertyList (id) {
      this.loading = true
      const statuses = [
        constants.rooof.Status.ACTIVE,
        constants.rooof.Status.ONBOARDING,
        constants.rooof.Status.OFFBOARDING,
        constants.rooof.Status.PENDING,
        constants.rooof.Status.PRETRIAL,
        constants.rooof.Status.TRIAL,
        constants.rooof.Status.INVALID,
        constants.rooof.Status.AUTO_CREATED
      ]
      const params = new URLSearchParams(statuses.map(status => ['status', status]))
      const response = await RooofAPI.companies.propertySummary({ id, params })
      if (response) {
        this.properties = response.data
      }
      this.loading = false
    },
    /**
     * Handler for filter change event.
     *
     * @param {Object} filters - updated filter values
     */
    handleFilterChange (filters) {
      this.filters = filters
    }
  }
}
</script>
