<template>
  <div>
    <CRow>
      <CCol>
        <RDataTable
          :items="tableData"
          :fields="columns"
          :table-props="{ loading }"
        >
          <template #name="{ item }">
            <td>
              <CLink :to="getLocation(item)">
                {{ item.name }}
              </CLink>
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <RWidgetStatus :status="item.status" />
            </td>
          </template>
          <template #active_start_date="{ item }">
            <td>
              {{ item.active_start_date ? formatDate(item.active_start_date, 'MMM dd, yyyy') : '' }}
            </td>
          </template>
          <template #end_date="{ item }">
            <td>
              {{ item.end_date ? formatDate(item.end_date, 'MMM dd, yyyy') : '' }}
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { RDataTable } from '@/components/tables'
import { RWidgetStatus } from '@/components/widgets'
import { formatDate } from '@/utils'
import constants from '@/utils/constants'

const columns = [
  {
    key: 'name',
    label: 'Property Name',
    _style: 'width: 16%'
  },
  {
    key: 'phone',
    label: 'Phone',
    _style: 'width: 8%'
  },
  {
    key: 'email',
    label: 'Email',
    _style: 'width: 18%'
  },
  {
    key: 'street',
    label: 'Address',
    _style: 'width: 18%'
  },
  {
    key: 'city',
    label: 'City',
    _style: 'width: 8%'
  },
  {
    key: 'state',
    label: 'State',
    _style: 'width: 4%'
  },
  {
    key: 'active_start_date',
    label: 'Start Date',
    _style: 'width: 10%'
  },
  {
    key: 'end_date',
    label: 'End Date',
    _style: 'width: 10%'
  },
  {
    key: 'status',
    label: 'Status',
    _style: 'width: 8%'
  }
]
// Default ordering for the status column
const statusOrderMap = {
  [constants.rooof.Status.ACTIVE]: 0,
  [constants.rooof.Status.INVALID]: 1,
  [constants.rooof.Status.PENDING]: 2,
  [constants.rooof.Status.PRETRIAL]: 3,
  [constants.rooof.Status.TRIAL]: 4,
  [constants.rooof.Status.ONBOARDING]: 5,
  [constants.rooof.Status.OFFBOARDING]: 6,
  [constants.rooof.Status.INACTIVE]: 7
}

export default {
  name: 'ActivePropertyTable',
  components: {
    RDataTable,
    RWidgetStatus
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns
    }
  },
  computed: {
    tableData () {
      // Map address values directly to row obj, allows automatic sort/filter
      return this.data.map(property => {
        property.street = property.address.street
        property.city = property.address.city
        property.state = property.address.state
        return property
      }).sort((a, b) => {
        // Apply default sort: status, then name
        return (
          statusOrderMap[a.status] - statusOrderMap[b.status] ||
          a.name.localeCompare(b.name)
        )
      })
    }
  },
  created () {
    this.formatDate = formatDate
  },
  methods: {
    /**
     * Generate the route location object for navigating to
     * the property reporting overview
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'Reporting',
        params: {
          cid: this.$route.params.cid,
          pid: row.id
        }
      }
    }
  }
}
</script>
