var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',[_c('RDataTable',{attrs:{"items":_vm.tableData,"fields":_vm.columns,"table-props":{ loading: _vm.loading }},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":_vm.getLocation(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('RWidgetStatus',{attrs:{"status":item.status}})],1)]}},{key:"active_start_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.active_start_date ? _vm.formatDate(item.active_start_date, 'MMM dd, yyyy') : '')+" ")])]}},{key:"end_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.end_date ? _vm.formatDate(item.end_date, 'MMM dd, yyyy') : '')+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }