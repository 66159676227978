<template>
  <CModal
    :show="show"
    :centered="true"
    :close-on-backdrop="false"
    size="lg"
    @update:show="close"
  >
    <template #header>
      <div>
        <h5 class="modal-title">
          New Property Request
        </h5>
        <p>
          Please fill out the following information and our staff will be in touch
        </p>
      </div>
    </template>
    <RSpinnerOverlay :loading="loading" :opacity="0.7">
      <CForm>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.property_name.$model"
              label="Property Name"
              add-label-classes="required"
              :is-valid="validateField('property_name')"
              invalid-feedback="This field is required"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.street.$model"
              label="Street Address"
              add-label-classes="required"
              :is-valid="validateField('street')"
              invalid-feedback="This field is required"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="5">
            <CInput
              v-model.trim="$v.formData.city.$model"
              label="City"
              add-label-classes="required"
              :is-valid="validateField('city')"
              invalid-feedback="This field is required"
            />
          </CCol>
          <CCol sm="3">
            <CInput
              v-model.trim="$v.formData.state.$model"
              label="State/Province"
              add-label-classes="required"
              :is-valid="validateField('state')"
            >
              <template #invalid-feedback>
                <div v-if="!$v.formData.state.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.formData.state.maxLength" class="invalid-feedback">
                  This field must not exceed 2 characters
                </div>
              </template>
            </CInput>
          </CCol>
          <CCol sm="4">
            <CInput
              v-model.trim="$v.formData.postal.$model"
              label="Zip/Postal"
              add-label-classes="required"
              :is-valid="validateField('postal')"
              invalid-feedback="This field is required"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="5">
            <CInput
              v-model.trim="$v.formData.country.$model"
              label="Country"
              add-label-classes="required"
              :is-valid="validateField('country')"
              invalid-feedback="This field is required"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.property_website.$model"
              label="Property Website"
              add-label-classes="required"
              :is-valid="validateField('property_website')"
            >
              <template #invalid-feedback>
                <div v-if="!$v.formData.property_website.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.formData.property_website.url" class="invalid-feedback">
                  This field must be a valid URL
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="3">
            <CInput
              v-model="$v.formData.unit_count.$model"
              type="number"
              label="Property Unit Count"
              add-label-classes="required"
              :is-valid="validateField('unit_count')"
            >
              <template #invalid-feedback>
                <div v-if="!$v.formData.unit_count.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.formData.unit_count.integer" class="invalid-feedback">
                  This field must be a number
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.leasing_email.$model"
              label="Leasing Email"
              add-label-classes="required"
              description="This is where we will send your training and installation package"
              :is-valid="validateField('leasing_email')"
            >
              <template #invalid-feedback>
                <div v-if="!$v.formData.leasing_email.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.formData.leasing_email.email" class="invalid-feedback">
                  This field must be an email
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.billing_email.$model"
              label="Billing Email"
              add-label-classes="required"
              :is-valid="validateField('billing_email')"
            >
              <template #invalid-feedback>
                <div v-if="!$v.formData.billing_email.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.formData.billing_email.email" class="invalid-feedback">
                  This field must be an email
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.formData.agreement_email.$model"
              label="Where should we email the property agreement?"
              description="We will only send an agreement if required by your management company"
              :is-valid="validateField('agreement_email')"
              invalid-feedback="This field must be an email"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="3">
            <label class="required">Desired Start Date </label>
            <v-date-picker
              value=""
              @input="formData.desired_start_date = formatDate($event, 'yyyy-MM-dd')"
            >
              <CInput
                v-model="$v.formData.desired_start_date.$model"
                :is-valid="validateField('desired_start_date')"
                invalid-feedback="This field is required"
              />
            </v-date-picker>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CTextarea
              v-model.trim="formData.comments"
              label="Special Requirements or Comments"
            />
          </CCol>
        </CRow>
      </CForm>
    </RSpinnerOverlay>
    <template #footer>
      <CButton shape="pill" :disabled="loading" @click="close">
        Cancel
      </CButton>
      <CButton
        shape="pill"
        color="info"
        :disabled="loading"
        @click="submit"
      >
        Submit Request
      </CButton>
    </template>
  </CModal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, integer, maxLength, required, url } from 'vuelidate/lib/validators'

import { RSpinnerOverlay } from '@/components/spinners'
import { RooofAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'

export default {
  name: 'PropertyActivationModal',
  components: { RSpinnerOverlay },
  mixins: [validationMixin],
  validations: {
    formData: {
      property_name: { required },
      property_website: { required, url },
      street: { required },
      city: { required },
      state: { required, maxLength: maxLength(2) },
      postal: { required },
      country: { required },
      unit_count: { required, integer },
      leasing_email: { required, email },
      billing_email: { required, email },
      agreement_email: { email },
      desired_start_date: { required }
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      formData: this.getFormConfig(),
      loading: false
    }
  },
  created () {
    this.formatDate = formatDate
  },
  methods: {
    /**
     * Returns the form fields with their default values.
     *
     * @returns {Object}
     */
    getFormConfig () {
      return {
        property_name: '',
        property_website: '',
        street: '',
        city: '',
        state: '',
        postal: '',
        country: '',
        unit_count: null,
        leasing_email: '',
        billing_email: '',
        agreement_email: '',
        desired_start_date: '',
        comments: ''
      }
    },
    /**
     * Validate a form field.
     *
     * @param {String} fieldName
     * @returns {Boolean}
     */
    validateField (fieldName) {
      const field = this.$v.formData[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    /**
     * Resets form and closes modal.
     */
    close () {
      this.formData = this.getFormConfig()
      this.$v.$reset()
      this.$emit('close')
    },
    /**
     * Validates and submits form
     */
    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      try {
        this.loading = true
        const payload = {
          type: 'activate',
          ...this.formData
        }
        await RooofAPI.activateProperty.create({ data: payload }, false)
        this.$store.commit('notification/show', {
          message: 'Thank you! Your request has been sent to our support team. They will be in touch soon.',
          type: 'success',
          duration: 6
        })
      } catch (err) {
        this.$store.dispatch('error/show', err)
      } finally {
        this.close()
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
header p {
  margin-bottom: 0;
}
::v-deep .required::after {
  content: '*';
}
</style>
